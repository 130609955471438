<template>
  <v-form>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field label="Name" outlined v-model="patientInformation.name">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          label="Birth date"
          outlined
          v-model="patientInformation.birthDate"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="2">
        <!--          <v-text-field label="Sex">-->

        <!--          </v-text-field>-->
        <v-select
          :items="['Male', 'Female']"
          label="Gender"
          v-model="patientInformation.sex"
          outlined
        ></v-select>
      </v-col>

      <v-col cols="12" md="2">
        <v-text-field
          label="Height"
          v-model="patientInformation.height"
          outlined
        >
          v-model="height"
        </v-text-field>
      </v-col>

      <v-col cols="12" md="2">
        <v-text-field
          v-model="patientInformation.weight"
          label="Weight"
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="5">
        <v-text-field
          v-model="patientInformation.address"
          outlined
          label="Address"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field outlined label="City" v-model="patientInformation.city">
        </v-text-field>
      </v-col>

      <v-col cols="12" md="2">
        <v-autocomplete
          outlined
          v-model="patientInformation.state"
          :items="states"
          auto-select-first
          label="State"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field outlined label="Zip" v-model="patientInformation.zip">
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          outlined
          label="Social Security Number"
          v-model="patientInformation.socialSecurityNumber"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          outlined
          label="Home Phone"
          v-model="patientInformation.homePhone"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          outlined
          label="Mobile Phone"
          v-model="patientInformation.mobile"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="9">
        <v-text-field
          outlined
          label="Occupation/Employer"
          v-model="patientInformation.occupation"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          outlined
          label="Work Phone"
          v-model="patientInformation.workPhone"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="9">
        <v-text-field
          outlined
          label="
              Spouse or Parent's Name"
          v-model="patientInformation.spouseOrParent"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          outlined
          label="Work Phone"
          v-model="patientInformation.workPhone2"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-radio-group
        v-model="patientInformation.hasPermanent"
        class="font-weight-bold ms-4"
        label="Have a different Permanent Address?"
        row
      >
        <v-radio label="Yes" :value="true"></v-radio>
        <v-radio label="No " :value="false"></v-radio>
      </v-radio-group>
    </v-row>
    <v-scroll-x-transition>
      <v-row v-if="patientInformation.hasPermanent">
        <v-col cols="12" md="5">
          <v-text-field
            v-model="patientInformation.permanentAddress"
            outlined
            label="Address"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            outlined
            label="City"
            v-model="patientInformation.permanentCity"
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" md="2">
          <v-autocomplete
            outlined
            v-model="patientInformation.permanentState"
            :items="states"
            auto-select-first
            label="State"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            outlined
            label="Zip"
            v-model="patientInformation.permanentZip"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-scroll-x-transition>
    <v-row>
      <v-radio-group
        v-model="patientInformation.isStudent"
        class="font-weight-bold ms-4"
        label="Are You a Collage Student?"
        row
      >
        <v-radio label="Yes" :value="true"></v-radio>
        <v-radio label="No " :value="false"></v-radio>
      </v-radio-group>
    </v-row>

    <v-scroll-x-transition>
      <v-row v-show="patientInformation.isStudent">
        <v-col cols="12">
          <v-text-field
            outlined
            label="Collage"
            v-model="patientInformation.collageName"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-scroll-x-transition>

    <v-row>
      <v-col cols="12">
        <v-text-field
          outlined
          label="Whom may we thank for referring you?"
          v-model="patientInformation.referral"
          v-on:change="$emit('patient-information', this.patientInformation)"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="9">
        <v-text-field
          outlined
          label="Person to contact in case of emergency"
          v-model="patientInformation.emergencyContactName"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          outlined
          label="Emergency Phone"
          v-model="patientInformation.emergencyContactPhone"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "PatientInformationForm",
  data: () => {
    return {
      patientInformation: {
        name: "",
        birthDate: "",
        sex: "",
        height: "",
        weight: "",
        address: "",
        city: "",
        state: "Massachusetts",
        zip: "",
        email: "",
        socialSecurityNumber: "",
        homePhone: "",
        mobile: "",
        occupation: "",
        workPhone: "",
        spouseOrParent: "",
        workPhone2: "",

        hasPermanent: null,
        permanentAddress: "",
        permanentCity: "",
        permanentState: "",
        permanentZip: "",

        isStudent: null,
        collageName: "",

        referral: "",
        emergencyContactName: "",
        emergencyContactPhone: "",
      },

      states: [
        "Alabama",
        "Alaska",
        "American Samoa",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District of Columbia",
        "Federated States of Micronesia",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Marshall Islands",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Northern Mariana Islands",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Palau",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virgin Island",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ],
    };
  },
};
</script>

<style scoped></style>
