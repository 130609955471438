<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.png')"
          class="my-3 p-5 m-5"
          contain
          height="50"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">Welcome to Copley</h1>

        <p class="subheading font-weight-regular">
          This portal is dedicate for client communication and fill client data.
          <br />Please fill forms accurately
        </p>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-btn large to="/contact" class="primary ms-2 me-2 mt-3"
        >Contact Us</v-btn
      ><v-btn large to="/booking" class="primary ms-2 me-2 mt-3"
        >Request Appointment</v-btn
      ><v-btn large to="medical-history" class="primary ms-2 me-2 mt-3"
        >Medical History</v-btn
      >
    </div>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({}),
};
</script>
