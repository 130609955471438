<template>
  <v-container class="pt-6">
    <h2 class="pt-5 pb-5">Medical History  </h2>
    <!--    <hr class="p-2" />-->
    <PatientInformationForm></PatientInformationForm>

    {{ patientInformation }}
  </v-container>
</template>

<script>
import PatientInformationForm from "../components/forms/PatientInformationForm";
export default {
  name: "medical_history",
  data: () => {
    return {
      patientInformation: {},
    };
  },
  components: {
    PatientInformationForm,
  },
};
</script>

<style scoped></style>
