<template>
  <v-container class="mx-auto pt-6" style="max-width: 400px">
    <h2>Contact us</h2>
    <v-form ref="cform" :disabled="isDisabled">
      <v-alert v-if="success" border="left" type="success"
      >Message Sent
      </v-alert>
      <v-text-field
          v-model="firstName"
          :rules="notEmptyRules"
          label="First Name *"
          prepend-icon="mdi-account-circle"
      ></v-text-field>
      <v-text-field
          v-model="lastName"
          :rules="notEmptyRules"
          label="Last Name *"
          prepend-icon="mdi-account-circle"
      ></v-text-field>
      <v-text-field
          v-model="email"
          :rules="emailRules"
          label="Email *"
          prepend-icon="mdi-email"
      ></v-text-field>
      <v-text-field
          v-model="mobile"
          :rules="notEmptyRules"
          label="Mobile *"
          prepend-icon="mdi-phone"
      ></v-text-field>
      <v-textarea
          v-model="message"
          auto-grow
          background-color="#fff"
          filled
          label="Message"
          prepend-icon="mdi-clipboard-edit-outline"
          row-height="30"
          rows="4"
      ></v-textarea>
      <v-row justify="end">
        <v-btn color="primary" @click="submitForm" :disabled="isDisabled">Send Message</v-btn>
      </v-row>
    </v-form>

  </v-container>
</template>

<script>
import {variables} from "../variables";

export default {
  name: "contact",
  methods: {
    reset() {
      this.$refs.cform.reset();
    },
    autoRemoveMsg() {
      setTimeout(() => (this.success = false), 2000);
    },
    submitForm: function () {
      if (this.$refs.cform.validate()) {
        console.log("form is valid");
        this.isDisabled = true;
        var formdata = new FormData();
        formdata.append("firstName", this.firstName);
        formdata.append("lastName", this.lastName);
        formdata.append("fullName", this.firstName + " " + this.lastName);
        formdata.append("mobile", this.mobile);
        formdata.append("email", this.email);
        formdata.append("message", this.message);
        formdata.append("pageUrl", "/contact");
        formdata.append("subject", "sdfa");

        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };


        fetch(variables.baseUrl + "/api/contact/100000", requestOptions)
            .then((response) => response.text())
            .then((result) => {
              console.log(result);
              this.reset();
              this.success = true;
              this.autoRemoveMsg();
              this.isDisabled = false;
            })
            .catch((error) => console.log("error", error));
      }
    },
  },
  data: () => {
    return {
      email: "",
      success: false,
      firstName: "",
      lastName: "",
      mobile: "",
      message: "",
      isDisabled: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      notEmptyRules: [(value) => !!value || "This Field Should be filled"],
    };
  },
};
</script>

<style scoped></style>
