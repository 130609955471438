<template>
  <v-container class="pt-6 m-auto">
    <h2>Booking Form</h2>
    <br />
    <v-alert v-if="success" border="left" type="success"
    >Thanks for contacting us! We will get in touch with you shortly.
    </v-alert>
    <v-form v-if="!success" ref="booking" :disabled="isDisabled">

      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="firstName"
            :rules="atLeastTwoRules"
            label="First Name"
            prepend-icon="mdi-account-circle"
            required
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="lastName"
            label="Last Name"
            :rules="atLeastTwoRules"
            prepend-icon="mdi-account-circle"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="email"
            label="email"
            :rules="emailRules"
            prepend-icon="mdi-email"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-radio-group
            v-model="currentPatient"
            label="Are you a current Patient?"
            prepend-icon="mdi-hospital-building"
            row
          >
            <v-radio label="yes" :value="true"></v-radio>
            <v-radio label="no" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Desired Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" scrollable>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="modal = false">
                Cancel
              </v-btn>
              <v-btn color="primary" text @click="$refs.dialog.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col>
          <v-select
            v-model="desiredHour"
            :items="availableHours"
            label="Desired Hour"
            prepend-icon="mdi-clock"
            required
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-select
            v-model="secondOption"
            :items="availableHours"
            label="Second Option "
            prepend-icon="mdi-clock"
            required
          ></v-select>
        </v-col>
        <v-col>
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="birthdate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="birthdate"
                label="Birth Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="birthdate" scrollable>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="modal = false">
                Cancel
              </v-btn>
              <v-btn color="primary" text @click="$refs.dialog.save(birthdate)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            label="Address *"
            v-model="address"
            :rules="atLeastTwoRules"
            prepend-icon="mdi-home"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="City *"
            v-model="city"
            :rules="atLeastTwoRules"
            prepend-icon="mdi-city"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="state"
            :items="states"
            auto-select-first
            label="State"
            prepend-icon="mdi-home-map-marker"
          ></v-autocomplete>
        </v-col>
        <v-col>
          <v-text-field
            v-model="zip"
            :rules="zipRules"
            label="Zip"
            prepend-icon="mdi-map-marker-radius"
            type="text"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            label="Phone *"
            v-model="phone"
            prepend-icon="mdi-phone"
            :rules="atLeastTwoRules"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="dentalInsurance"
            label="Dental Insurance"
            prepend-icon="mdi-tooth"
            type="text"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="insuranceId"
            label="Insurance Id"
            prepend-icon="mdi-barcode"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="employer"
            label="Employer"
            prepend-icon="mdi-account-box-outline"
            type="text"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-textarea
            v-model="referral"
            label="How did you hear about us"
            name="input-7-1"
            outlined
            prepend-inner-icon="mdi-ear-hearing"
          ></v-textarea>
        </v-col>
        <v-col>
          <v-textarea
              v-model="purpose"
            label="Purpose of Visit"
            name=""
            outlined
            prepend-inner-icon="mdi-comment"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox
            v-model="agreedToTerms"
            :rules="agreedToTermsRules"
            label="Agree to terms & conditions"
            required
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-flex>
        <div style="height: 20px;padding-bottom: 5px">


        <small v-if="isInvalid" style="color: red" >Make sure to fill all required fields </small>
        </div>
        <v-spacer></v-spacer>
        <v-btn :class="[isInvalid ? 'danger' : 'primary']"  @click="submitForm()" :disabled="isDisabled"> Submit Form</v-btn>
      </v-flex>
    </v-form>
  </v-container>
</template>

<script>
import {variables} from "../variables";

export default {
  name: "App",
  data: () => {
    return {
      firstName: "",
      lastName: "",
      email: "",
      isClient: "",
      dentalInsurance: "",
      insuranceId: "",
      employer: "",
      referral: "",
      purpose: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      desiredHour: "9 am",
      secondOption: "9 am",
      birthdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      zip: null,
      agreedToTerms: false,
      address: "",
      city: "",
      phone: "",
      currentPatient: false,
      state: "Massachusetts",

      // Validation rules
      zipRules: [
        (value) => /^\d{5}(-\d{4})?$/.test(value) || "Please enter a valid zip",
        // value=> value.length < 1 || 'Please enter zip',
      ],
      agreedToTermsRules: [(value) => !!value || "you must agree to terms"],
      emailRules: [
        (value) => !!value || "This Field should be filled",
        (value) => value.length > 2 || "This Field should be filled",
      ],

      atLeastTwoRules: [
        (value) => !!value || "This Field should be filled",
        (value) => value.length > 2 || "This Field should be filled",
      ],

      modal: false,
      // options
      availableHours: [
        "9 am",
        "10 am",
        "11 am",
        "12 noon",
        "1 pm",
        "2 pm",
        "3 pm",
        "4 pm",
        "5 pm",
        "6 pm",
      ],
      states: [
        "Alabama",
        "Alaska",
        "American Samoa",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District of Columbia",
        "Federated States of Micronesia",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Marshall Islands",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Northern Mariana Islands",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Palau",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virgin Island",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ],
      isDisabled: false,
      isInvalid: false,
      success: false
    };
  },
  methods: {
    reset() {
      this.$refs.booking.reset();
    },
    autoRemoveMsg() {
      setTimeout(() => (this.success = false), 3000);
    },
    autoRemoveInvalidAlert(){
      setTimeout(() => (this.isInvalid = false), 2600);
    },
    submitForm() {
      if (this.$refs.booking.validate()) {
        this.isDisabled =true;

        var formData = new FormData();
        formData.append("firstName", this.firstName);
        formData.append("lastName", this.lastName);
        formData.append("email", this.email);
        formData.append("currentPatient", this.currentPatient);
        formData.append("desiredDate", this.date);
        formData.append("desiredHour", this.desiredHour);
        formData.append("secondOption", this.secondOption);
        formData.append("birthDate", this.birthdate);
        formData.append("address", this.address);
        formData.append("city", this.city);
        formData.append("state", this.state);
        formData.append("zip", this.zip);
        formData.append("phone", this.phone);
        formData.append("dentalInsurance", this.dentalInsurance);
        formData.append("insuranceId", this.insuranceId);
        formData.append("employer", this.employer);
        formData.append("referral", this.referral);
        formData.append("purpose", this.purpose);

        var requestOptions = {
          method: "POST",
          body: formData,
          redirect: "follow",
        };

        fetch(variables.baseUrl + "/api/booking", requestOptions)
          .then((response) => response.text())
          .then((result) => {
            console.log(result);
            this.isDisabled= false;

            this.reset();
            this.success = true;
            this.autoRemoveMsg();
            this.isDisabled = false;



          })
          .catch((error) => console.log("error", error));

      }else{
        this.isInvalid = true;
        this.autoRemoveInvalidAlert();
      }
    },
  },
};
</script>
