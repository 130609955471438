<template>
  <v-app>
    <v-app-bar color="blue" dense dark elevation="9" rounded app>
      <v-toolbar-title style="color: white"
        >Copley Dental Forms</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn
        v-for="link in links"
        :key="link.label"
        small
        class="me-3"
        color="white"
        :to="link.url"
        rounded
        text
      >
        {{ link.label }}</v-btn
      >

      <v-btn small href="https://copleydental.com"> Go to site </v-btn>
    </v-app-bar>



    <v-main>
<!--      <v-menu offset-y>-->
<!--        <template v-slot:activator="{ on, attrs }">-->
<!--          <v-btn-->
<!--              color="primary"-->
<!--              dark-->
<!--              v-bind="attrs"-->
<!--              v-on="on"-->
<!--          >-->
<!--            Dropdown-->
<!--          </v-btn>-->
<!--        </template>-->
<!--        <v-list>-->
<!--          <v-list-item-->
<!--              v-for="(link, index) in links"-->
<!--              :key="index"-->
<!--              link-->
<!--          >-->
<!--            <v-list-item-title :to="link.url" >{{ link.label }}</v-list-item-title>-->
<!--          </v-list-item>-->
<!--        </v-list>-->
<!--      </v-menu>-->
      <router-view />
    </v-main>

    <v-footer padless>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} - Copley Dental Forms
        <small><strong> Built using Arzs forms</strong></small>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    baseUrl: "https://panel.copleydentalforms.com",
    //
    links: [
      {
        label: "Home",
        url: "/",
      },
      {
        label: "Contact us",
        url: "/contact",
      },
      {
        label: "Booking",
        url: "/booking",
      },
      {
        label: "Medical History",
        url: "/medical-history",
      },
    ],
  }),
};
</script>
